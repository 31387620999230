import styled from "@emotion/styled"
//import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Headroom from "react-headroom"
import { IoMdFlash } from "react-icons/io"
import tw from "twin.macro"
import "./darkTheme.css"
import "./header.css"
import Language from "../components/Language"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const Header = ({ siteTitle }) => (
  <Headroom disableInlineStyles>
    <HeaderContainer>
      <InnerHeader>
        {/*<ThemeToggler />*/}
        <InnerLogo>
          <Logo>
            <Link
              to="/"
              aria-label="Sol Etchegaray - Volver al inicio"
              className="flex items-baseline text-2xl text-gray-900 uppercase duration-200"
            >
              <IoMdFlash className="mr-1 text-2xl text-yellow-700 transform translate-y-1 flash" />
              {siteTitle}
            </Link>
          </Logo>
        </InnerLogo>

        <Nav>
          <Link
            activeClassName="active"
            className="text-lg font-bold tracking-wider text-white"
            partiallyActive={true}
            to="/albums"
          >
            <FormattedMessage id="see_gallery" />
          </Link>

          <Link
            activeClassName="active"
            className="text-lg font-bold tracking-wider text-white "
            partiallyActive={true}
            to="/contact"
          >
            <FormattedMessage id="see_contact" />
          </Link>
          <div className="absolute top-0 right-0 hidden pr-12 ml-4 lg:pr-0 lg:block">
            <Language />
          </div>
        </Nav>
      </InnerHeader>
    </HeaderContainer>
  </Headroom>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default injectIntl(Header)

const Nav = styled.nav`
  ${tw`justify-end hidden lg:flex`}
`

const HeaderContainer = styled.header`
  ${tw`z-50 px-2 py-0 md:py-0`}
  body.home & {
    background: rgba(255, 255, 255, 0.2);
  }
`

const InnerHeader = styled.div`
  ${tw`flex items-center justify-start max-w-4xl px-0 py-3 m-auto lg:justify-between sm:pr-6 md:pr-0 lg:py-1`}

  .insta-logo {
    ${tw`text-white`}
  }
`

const InnerLogo = styled.div`
  ${tw`m-0 md:pl-0`}
`

const Logo = styled.h1`
  ${tw`m-0 md:pl-0`}
`
