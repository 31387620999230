import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
  es: "Es",
  en: "En",
}

const Language = () => (
  <>
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages.map((language) => (
          <button
            key={language}
            onClick={() => changeLocale(language)}
            style={{
              color: currentLocale === language ? `#f1f1f1` : `white`,
              fontWeight: currentLocale === language ? `bold` : `light`,
              margin: 10,
              cursor: `pointer`,
            }}
            className="py-2 uppercase"
          >
            {languageName[language]}
          </button>
        ))
      }
    </IntlContextConsumer>
  </>
)

export default Language
