import styled from "@emotion/styled"
import PropTypes from "prop-types"
import React from "react"
import SimpleReactLightbox from "simple-react-lightbox"
import tw from "twin.macro"
import { useStaticQuery, graphql } from "gatsby"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"
import SEO from "./seo"
import Sidebar from "./Sidebar"
import Header from "./header"

import STNlogo from "../assets/trinomia.svg"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery3 {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const intl = useIntl()

  return (
    <>
      <SEO lang={intl.locale} />
      <Sidebar />
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="app-container">
        <SimpleReactLightbox>
          <main>{children}</main>
        </SimpleReactLightbox>
        <Footer className="">
          <div className="flex flex-col justify-center max-w-4xl m-auto text-base ">
            <div className="flex flex-col items-center justify-center flex-1 w-full max-w-4xl pt-2 mt-2 md:items-baseline md:flex-row">
              <Link
                to="/contact"
                className="px-0 mx-0 border-b hover:border-yellow-700"
              >
                <FormattedMessage id="contact" />
              </Link>
            </div>
            <div className="flex justify-center pt-6 pb-1">
              <span className="block py-1">
                &#xA9; SolEtchegaray {new Date().getFullYear()}
              </span>
            </div>
          </div>
        </Footer>
        <Stn>
          <div className="flex items-center justify-center font-mono transition-all duration-700 opacity-25 hover:opacity-100">
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="creado por santuan"
              href="https://www.santuan.com.ar"
            >
              <STNlogo className="w-16" />
            </a>
          </div>
        </Stn>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Footer = styled.footer`
  ${tw`relative px-4 py-8 text-gray-800 bg-black `}
  span {
    ${tw`text-white`}
  }
`

const Stn = styled.div`
  ${tw`p-2 py-0 text-center text-gray-800 bg-black `}
  svg {
    opacity: 0.5;
  }
  svg {
    opacity: 1;
  }

  a {
    filter: invert(1);
  }
`
