import React from "react"
import { Link, FormattedMessage } from "gatsby-plugin-intl"


const Navigation = ({ closeMenu }) => (
  <nav className="flex flex-col w-full">
    <Link
      onClick={closeMenu}
      activeClassName="opacity-50"
      aria-label="Ver la Galería"
      to="/albums"
      className="py-2 mb-2 font-mono text-base text-gray-100 border-b border-gray-700 cursor-pointer hover:text-gray-300"
    >
      <FormattedMessage id="see_gallery" />
    </Link>
    <Link
      onClick={closeMenu}
      activeClassName="opacity-50"
      aria-label="Ver la Galería"
      to="/albums/autorretratos"
      className="py-2 mb-2 font-mono text-base text-gray-100 border-b border-gray-700 cursor-pointer hover:text-gray-300"
    >
      <FormattedMessage id="cat_self" />
    </Link>
    <Link
      onClick={closeMenu}
      activeClassName="opacity-50"
      aria-label="Ver la Galería"
      to="/albums/cuerpas"
      className="py-2 mb-2 font-mono text-base text-gray-100 border-b border-gray-700 cursor-pointer hover:text-gray-300"
    >
      <FormattedMessage id="cat_bodies" />
    </Link>
    <Link
      onClick={closeMenu}
      activeClassName="opacity-50"
      aria-label="Ver las bandas"
      to="/albums/bandas"
      className="py-2 mb-2 font-mono text-base text-gray-100 border-b border-gray-700 cursor-pointer hover:text-gray-300"
    >
      <FormattedMessage id="cat_bands" />
    </Link>
    <Link
      onClick={closeMenu}
      activeClassName="opacity-50"
      aria-label="Ver Marcas"
      to="/albums/marcas"
      className="py-2 mb-2 font-mono text-base text-gray-100 border-b border-gray-700 cursor-pointer hover:text-gray-300"
    >
      <FormattedMessage id="cat_brands" />
    </Link>
    <Link
      onClick={closeMenu}
      activeClassName="opacity-50"
      aria-label="ir a página de contacto"
      to="/contact"
      className="py-2 mb-2 font-mono text-base text-gray-100 border-b border-gray-700 cursor-pointer hover:text-gray-300"
    >
      <FormattedMessage id="see_contact" />
    </Link>
  </nav>
)

export default Navigation
